import { useRouter } from "next/router"
import * as React from "react"
import { LoginSkeleton } from "src/components/ui/Skeleton"
import { useAuthContext } from "src/hooks/core/useAuthContext"

const Index = () => {
  const { session, isLoading } = useAuthContext()
  const router = useRouter()

  if (!isLoading && router.isReady) {
    if (session) {
      router.push("/app/profile")
    } else {
      const email = router.query.email as string
      const queryString = email ? `?${new URLSearchParams({ email }).toString()}` : ""
      router.push("/login" + queryString)
    }
  }
  return <LoginSkeleton />
}

export default Index
